export {
  AttendeeContainer,
  AttendeeProvider,
  useAttendee,
} from './AttendeeContainer';
export type {Attendee, AttendeeModel} from './attendee-container-machine';
export {readAccessToken} from './attendee-session-token';

import type {
  GuestAuthChangeEvent,
  GuestAuthChangeEventName,
  GuestAuthLogoutEvent,
  GuestAuthLogoutEventName,
  GuestAuthSuccessEvent,
  GuestAuthSuccessEventName,
} from '@backstage-components/base';

declare global {
  // Extends `#addEventListener` and `#removeEventListener` type definition to
  // include an overload for the custom events dispatched during guest
  // authentication. These are copied from the module implementations so
  // consumers of the `@backstage-components/attendee-container` package will
  // also have access to them.
  interface HTMLElement {
    addEventListener(
      type: GuestAuthLogoutEventName,
      listener: (this: HTMLElement, ev: GuestAuthLogoutEvent) => void,
      options?: boolean | AddEventListenerOptions
    ): void;
    addEventListener(
      type: GuestAuthSuccessEventName,
      listener: (this: HTMLElement, ev: GuestAuthSuccessEvent) => void,
      options?: boolean | AddEventListenerOptions
    ): void;
    addEventListener(
      type: GuestAuthChangeEventName,
      listener: (this: HTMLElement, ev: GuestAuthChangeEvent) => void,
      options?: boolean | AddEventListenerOptions
    ): void;
    removeEventListener(
      type: GuestAuthLogoutEventName,
      listener: (this: HTMLElement, ev: GuestAuthLogoutEvent) => void,
      options?: boolean | EventListenerOptions
    ): void;
    removeEventListener(
      type: GuestAuthSuccessEventName,
      listener: (this: HTMLElement, ev: GuestAuthSuccessEvent) => void,
      options?: boolean | EventListenerOptions
    ): void;
    removeEventListener(
      type: GuestAuthChangeEventName,
      listener: (this: HTMLElement, ev: GuestAuthChangeEvent) => void,
      options?: boolean | EventListenerOptions
    ): void;
  }
}
