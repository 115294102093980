import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {AccessCodeInstructionSchema} from '@backstage-components/access-code';
import {
  GuestInstructionSchema,
  useManagedRef,
  useShowInstructions,
  useSiteVersionId,
  type GuestAuthChangeEvent,
  type GuestAuthChangeEventName,
  type GuestAuthLogoutEvent,
  type GuestAuthLogoutEventName,
  type GuestAuthSuccessEvent,
  type GuestAuthSuccessEventName,
  type DeriveInstructionType,
} from '@backstage-components/base';
import {ComponentDefinition as DisneyOneidAuthComponent} from '@backstage-components/disney-oneid-auth';
import {ComponentDefinition as OpenLoginComponent} from '@backstage-components/open-login';
import {PublicAccessCodeInstructionSchema} from '@backstage-components/public-access-code';
import {assertNever} from '@backstage/utils/type-helpers';
import {Type} from '@sinclair/typebox';
import {useMachine} from '@xstate/react';
import {useObservableState, useSubscription} from 'observable-hooks';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {filter, map} from 'rxjs';
import {Attendee, ContainerMachine} from './attendee-container-machine';
import {readAccessToken} from './attendee-session-token';
import {useGuestStorage, type GuestStorage} from './hooks/useGuestStorage';

const InstructionSchema = Type.Union([
  ...AccessCodeInstructionSchema.anyOf,
  ...DisneyOneidAuthComponent.instructions.anyOf,
  ...GuestInstructionSchema.anyOf,
  ...PublicAccessCodeInstructionSchema.anyOf,
  ...OpenLoginComponent.instructions.anyOf,
]);

type InstructionType = DeriveInstructionType<typeof InstructionSchema>;

type VerifyInstructionFilter<T extends string> = T extends `${string}:verify`
  ? T
  : never;

type ModuleType<T extends string> = T extends `${infer K}:verify` ? K : never;

type VerifyInstructionType = VerifyInstructionFilter<InstructionType['type']>;

type VerificationModule = ModuleType<VerifyInstructionType>;

interface AttendeeContextValue {
  attendeeId: string;
  attendeeName: string;
  attendeeEmail: string | null;
  attendeeType: Attendee['attendeeType'];
  token?: string;
  attendeeTags: string[];
  avatar?: string;
  sessionToken?: string;
}

interface AttendeeProviderProps<ApolloCache = NormalizedCacheObject> {
  client: ApolloClient<ApolloCache>;
  attendeeId?: string;
  showId: string;
}

/**
 * @private exported for tests
 */
export const AttendeeContainer = createContext<
  AttendeeContextValue | undefined
>(undefined);
AttendeeContainer.displayName = 'AttendeeContainer';

/**
 * Context `Provider` to create and hold an attendee record.
 */
export const AttendeeProvider: FC<PropsWithChildren<AttendeeProviderProps>> = (
  props
) => {
  const {client, showId} = props;
  const siteVersionId = useSiteVersionId();
  const [state, dispatch] = useMachine(ContainerMachine, {
    input: {client, showId},
  });
  // reset state machine when the `showId` sent to props changes
  useEffect(() => {
    dispatch({type: 'RESET', meta: {showId}});
  }, [dispatch, showId]);
  const attendee: Attendee | undefined = useMemo(() => {
    if (
      (state.matches('idle') || state.matches('success')) &&
      'attendee' in state.context
    ) {
      return {
        id: state.context.attendee.id,
        name: state.context.attendee.name,
        email: state.context.attendee.email,
        chatTokens: state.context.attendee.chatTokens.filter(
          (token) => token.token.length > 0
        ),
        attendeeTags: state.context.attendeeTags || [],
        attendeeType: state.context.attendee.attendeeType,
        avatar: state.context.attendee.avatar,
      };
    } else {
      return undefined;
    }
  }, [state]);
  const attendeeRef = useManagedRef(attendee);
  // Compute initial value for `GuestStorage` based on `Attendee`
  const initialStorage: GuestStorage = useMemo(
    () => ({
      tags: attendee?.attendeeTags ?? [],
    }),
    [attendee]
  );
  // Hook into the Guest Storage system
  useGuestStorage(client, showId, initialStorage);
  // Listen for broadcasts from components of type `AccessCode` and `PublicAccessCode`.
  // when a verify request happens, forward it to the state machine.
  const {observable, broadcast} = useShowInstructions(InstructionSchema);
  /** Trigger a broadcast at the end of this tick */
  const sendBroadcast: typeof broadcast = useCallback(
    (instruction) => {
      queueMicrotask(() => broadcast(instruction));
    },
    [broadcast]
  );
  const authModuleType = useObservableState(
    observable.pipe(
      map((instruction) => instruction.type),
      filter(
        (instructionType): instructionType is VerifyInstructionType =>
          instructionType === 'AccessCode:verify' ||
          instructionType === 'DisneyOneidAuth:verify' ||
          instructionType === 'OpenLogin:verify' ||
          instructionType === 'PublicAccessCode:verify'
      ),
      map((instructionType): VerificationModule => {
        switch (instructionType) {
          case 'AccessCode:verify':
            return 'AccessCode';
          case 'DisneyOneidAuth:verify':
            return 'DisneyOneidAuth';
          case 'OpenLogin:verify':
            return 'OpenLogin';
          case 'PublicAccessCode:verify':
            return 'PublicAccessCode';
          default:
            assertNever(instructionType);
        }
      })
    )
  );
  useSubscription(observable, {
    next: (instruction) => {
      if (
        instruction.type === 'AccessCode:verify' &&
        instruction.meta.showId !== showId
      ) {
        sendBroadcast({
          type: 'AccessCode:failure',
          meta: {
            about: instruction.meta.about ?? undefined,
            reason: `ShowId mismatch expected: ${showId}, got: ${instruction.meta.showId}`,
          },
        });
      } else if (instruction.type === 'AccessCode:verify') {
        dispatch({
          type: 'VERIFY',
          meta: {
            about: instruction.meta.about,
            accessCode: instruction.meta.accessCode,
            showId,
            siteVersionId,
          },
        });
      } else if (
        instruction.type === 'DisneyOneidAuth:verify' &&
        instruction.meta.showId !== showId
      ) {
        sendBroadcast({
          type: 'DisneyOneidAuth:failure',
          meta: {
            about: instruction.meta.about ?? undefined,
            reason: `ShowId mismatch expected: ${showId}, got: ${instruction.meta.showId}`,
          },
        });
      } else if (instruction.type === 'DisneyOneidAuth:verify') {
        const currentAttendee = attendeeRef.current;
        // Uses logic from `guest-external-helpers` in `@backstage/api-types`,
        // if the pattern for generating attendee id changes this will stop
        // knowing how to "skip" reauthentication
        const externalAttendeeId = `oneid:${instruction.meta.swid}:attendee`;
        if (
          instruction.meta.shouldReauth === false &&
          currentAttendee?.id === externalAttendeeId
        ) {
          sendBroadcast({
            type: 'DisneyOneidAuth:verify-skipped',
            meta: {
              attendee: {
                ...currentAttendee,
                tags: currentAttendee.attendeeTags,
              },
            },
          });
        } else {
          dispatch({
            type: 'VERIFY_EXTERNAL',
            meta: {
              about: instruction.meta.about,
              email: instruction.meta.email,
              externalId: instruction.meta.swid,
              moduleId: instruction.meta.moduleId,
              name: instruction.meta.name,
              serviceType: 'oneid',
              showId,
              siteVersionId,
            },
          });
        }
      } else if (
        instruction.type === 'OpenLogin:verify' &&
        instruction.meta.showId !== showId
      ) {
        sendBroadcast({
          type: 'OpenLogin:failure',
          meta: {
            about: instruction.meta.about ?? undefined,
            reason: `ShowId mismatch expected: ${showId}, got: ${instruction.meta.showId}`,
          },
        });
      } else if (instruction.type === 'OpenLogin:verify') {
        dispatch({
          type: 'VERIFY_OPEN_LOGIN',
          meta: {
            about: instruction.meta.about,
            agreementAnswer: instruction.meta.agreementAnswer,
            agreementText: instruction.meta.agreementText,
            email: instruction.meta.email,
            moduleId: instruction.meta.moduleId,
            name: instruction.meta.name,
            showId,
            siteVersionId,
          },
        });
      } else if (
        instruction.type === 'PublicAccessCode:verify' &&
        instruction.meta.showId !== showId
      ) {
        sendBroadcast({
          type: 'PublicAccessCode:failure',
          meta: {
            about: instruction.meta.about ?? undefined,
            reason: `ShowId mismatch expected: ${showId}, got: ${instruction.meta.showId}`,
          },
        });
      } else if (instruction.type === 'PublicAccessCode:verify') {
        dispatch({
          type: 'VERIFY_PUBLIC',
          meta: {
            about: instruction.meta.about,
            moduleId: instruction.meta.moduleId,
            passCode: instruction.meta.passCode,
            showId,
            siteVersionId,
            name: instruction.meta.name,
          },
        });
      } else if (instruction.type === 'Guest:logout') {
        // Reset the state machine (which will clear `attendeeRef` and clear
        // the stored `sessionToken`)
        dispatch({type: 'RESET', meta: {}});
      }
    },
  });
  // When the state machine transitions, if `about` is set in context then
  // broadcast an instruction indicating success or failure.
  useEffect(() => {
    if (
      state.matches('failure') &&
      'reason' in state.context &&
      typeof authModuleType === 'string'
    ) {
      const meta = {about: state.context.about, reason: state.context.reason};
      broadcast({type: `${authModuleType}:failure`, meta});
    } else if (
      state.matches('success') &&
      'attendee' in state.context &&
      typeof authModuleType === 'string'
    ) {
      const {about, attendee} = state.context;
      const {chatTokens, email, id, name, attendeeTags: tags} = attendee;
      broadcast({
        type: `${authModuleType}:success`,
        meta: {about, attendee: {chatTokens, email, id, name, tags}},
      });
    } else if (state.matches({idle: 'logout'})) {
      const eventName: GuestAuthLogoutEventName = 'GuestAuth:logout';
      const logoutEvent: GuestAuthLogoutEvent = new CustomEvent(eventName, {
        detail: {},
      });
      document.body.dispatchEvent(logoutEvent);
    }
  }, [authModuleType, broadcast, state]);
  // Re-dispatch authentication success events as `GuestAuth:success`.
  // This indirection enables flows to react to instructions like `:on-success`
  // without the reload behavior triggered by `GuestAuth:success` interfering.
  // Additionally it means listeners only need to listen for one event rather
  // than however many authentication events end up existing.
  useEffect(() => {
    const onAccessCodeSuccess = (ev: GuestAuthSuccessEvent): void => {
      const eventName: GuestAuthSuccessEventName = 'GuestAuth:success';
      const autheEvent: GuestAuthSuccessEvent = new CustomEvent(eventName, {
        detail: {attendee: ev.detail.attendee, showId: ev.detail.showId},
      });
      document.body.dispatchEvent(autheEvent);
    };
    document.body.addEventListener('AccessCode:success', onAccessCodeSuccess);
    document.body.addEventListener(
      'GuestExternalAuth:success',
      onAccessCodeSuccess
    );
    document.body.addEventListener('OpenLogin:success', onAccessCodeSuccess);
    document.body.addEventListener(
      'PublicAccessCode:success',
      onAccessCodeSuccess
    );
    return () => {
      document.body.removeEventListener(
        'AccessCode:success',
        onAccessCodeSuccess
      );
      document.body.removeEventListener(
        'GuestExternalAuth:success',
        onAccessCodeSuccess
      );
      document.body.removeEventListener(
        'OpenLogin:success',
        onAccessCodeSuccess
      );
      document.body.removeEventListener(
        'PublicAccessCode:success',
        onAccessCodeSuccess
      );
    };
  }, []);
  // Respond to the `GuestAuth:logout` by broadcasting `:on-logout`
  useEffect(() => {
    const onGuestLogout = (_: GuestAuthLogoutEvent): void => {
      sendBroadcast({type: 'Guest:on-logout', meta: {}});
    };
    // Broadcast the `:on-logout` instruction in response to the
    // `GuestAuth:logout` event on the `document.body` so other modules (for
    // example `DisneyOneidAuth`) have a chance to perform logout behaviors
    // before the `:on-logout` flows are triggered
    document.body.addEventListener('GuestAuth:logout', onGuestLogout);
    return () => {
      document.body.removeEventListener('GuestAuth:logout', onGuestLogout);
    };
  }, [sendBroadcast]);

  const value: AttendeeContextValue | undefined = useMemo(() => {
    if (attendee) {
      return {
        attendeeId: attendee.id,
        attendeeName: attendee.name,
        attendeeEmail: attendee.email,
        attendeeType: attendee.attendeeType,
        token: attendee.chatTokens[0]?.token,
        attendeeTags: attendee.attendeeTags ?? [],
        avatar: attendee.avatar,
        sessionToken: readAccessToken(showId) ?? undefined,
      };
    } else {
      return undefined;
    }
  }, [attendee, showId]);

  // Dispatch an event on `document.body` when there is a change in
  // authentication status or when details about the currently authenticated
  // guest change.
  useEffect(() => {
    const eventName: GuestAuthChangeEventName = 'GuestAuth:change';
    let changeEvent: GuestAuthChangeEvent;
    if (state.value === 'init' || state.value === 'pending') {
      // If the current attendee is still being sorted out don't do anything
      return;
    } else if (typeof attendee !== 'undefined') {
      changeEvent = new CustomEvent(eventName, {
        detail: {
          attendee: {
            chatTokens: attendee.chatTokens,
            email: attendee.email,
            id: attendee.id,
            name: attendee.name,
            tags: attendee.attendeeTags,
          },
          showId,
        },
      });
    } else {
      changeEvent = new CustomEvent(eventName, {
        detail: {attendee: null, showId},
      });
    }
    document.body.dispatchEvent(changeEvent);
  }, [attendee, showId, state.value]);

  return <AttendeeContainer.Provider value={value} children={props.children} />;
};

/**
 * Gives access to the currently authenticated attendee if it exists.
 * @returns the attendee record if it exists, `null` if it does not.
 */
export const useAttendee = (): Attendee | null => {
  const context = useContext(AttendeeContainer);
  if (typeof context === 'undefined') {
    return null;
  } else {
    return attendeeFromContext(context);
  }
};

function attendeeFromContext(context: AttendeeContextValue): Attendee | null {
  if ('attendeeId' in context) {
    return {
      id: context.attendeeId,
      name: context.attendeeName,
      email: context.attendeeEmail ?? null,
      chatTokens:
        typeof context.token === 'string' ? [{token: context.token}] : [],
      attendeeTags: context.attendeeTags,
      attendeeType: context.attendeeType,
      avatar: context.avatar,
      sessionToken: context.sessionToken,
    };
  } else {
    return null;
  }
}
